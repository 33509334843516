import type { Maybe, TaxCode } from "~/graphql/propeller/generated";

export const NAV_CATEGORY_CODE = "NAV_CATEGORY_CODE";

export type ProductSearchType = "catalog" | "promotion" | "selection";

/** These identifiers could change in future syncs... */
export const CategoryList: any = {
  1753: { internalName: "beer", navCode: "BIERE", sortOrder: 2 },
  1755: { internalName: "wine", navCode: "VIN", sortOrder: 4 },
  1759: { internalName: "food", navCode: "FOOD", sortOrder: 7 },
  1761: { internalName: "spirits", navCode: "SPIRIT", sortOrder: 5 },
  1763: { internalName: "promo", navCode: "PUB", sortOrder: 8 },
  1765: { internalName: "soft", navCode: "SOFT", sortOrder: 3 },
  1767: { internalName: "hot", navCode: "BCHAUD", sortOrder: 6 },
};

export interface CategoryListItem {
  id: number;
  name: string;
  slug: string;
}

export interface CatalogOptions {
  searchType: ProductSearchType;
}

// eslint-disable-next-line unused-imports/no-unused-vars
const productSearchResultsView = {
  grid: "grid",
  list: "list",
} as const;

export type ProductSearchResultsView = (typeof productSearchResultsView)[keyof typeof productSearchResultsView];

export interface ProductSearchQueryParams {
  page: number;
  sortField: string;
  sortOrder: string;
  categoryId?: number;
  term?: string;
  textFilters?: string;
}

/** For search results, product lists... */
export interface ProductListItem {
  id: number;
  name: string;
  sku: string;
  productUrl: string;
  image: Maybe<ProductImage>;
  package: string;
  minimumOrderQuantity: number;
  incrementalOrderQuantity: number;

  /** Price needs to be requested. */
  isPriceOnDemand: boolean;

  /** Product will be placed in backorder. */
  isProductOnDemand: boolean;

  taxCode: TaxCode;
  supplierCode: string;

  analytics: ProductAnalyticsData;
}

export interface ProductAnalyticsData {
  id: string;
  name: string;
  brand?: string;
  category?: string;
  quantity?: number;
  price?: number;
  index?: number;
}

export interface ProductDetails extends ProductListItem {
  shortDescriptionHtml: string;

  /* attributes */
  ecoTax: number | null;
  brand: string;
  color: string;
  country: string;
  exciseDuties: number | null;
  deposit: number | null;
  alcohol: number | null;
  appelation: string | null;
  category: string | null;
  region: string | null;
  vintage: string | null;
  volume: number | null;
  brewery: string | null;
  type: string | null;

}

export interface ProductImage {
  imageUrl: string;
  alt: string;
};

/** Interface for the price fetch results. */
export interface PriceResult {
  items: ItemPriceResult[];
};

/** Single item price result. */
export interface ItemPriceResult {
  itemNo: string;

  /** Original pre promotion price. */
  originalUnitPrice: number | null;

  /** Actual price, might include a regular discount (which is not a promotion). */
  unitPrice: number | null;

  /** The customer-specific price. */
  customerPrice: number | null;

  /** Promotion discount amount. */
  discountAmount: number;
  priceBlockedForCustomer: boolean;

  /** Discount amount, discount product or free product. */
  hasAnyPromotion: boolean;

  /** Discount amount. */
  hasSpecialPrice: boolean;

  /** A free product. */
  hasFreeProduct: boolean;

  freeProductLines: FreeProductLine[];
  discountProductLines: DiscountProductLine[];

  priceScales: PriceScale[];
}

/** Interface for a price scale. */
export interface PriceScale {
  quantity: number;
  originalUnitPrice: number | null;
  unitPrice: number | null;
  customerPrice: number | null;
  priceBlockedForCustomer: boolean;
}

/** Article promotion where you get a free product. */
export interface FreeProductLine {
  /** Description from Navision. */
  description: string;

  /** Informative: code of the free product. */
  itemNo: string;

  /** Informative: UOM of the free product. */
  uom: string;

  /** Informative: number of times the product is granted. */
  freeQuantity: number;

  /** Number of the original product that must be bought to receive the promotion. */
  orderQuantity: number;
}

/** Article promotion where the discount is in the form of a product. */
export interface DiscountProductLine {
  /** Description from Navision. */
  description: string;

  /** Number of the original product that must be bought to receive the promotion. */
  _orderQuantity: number;

  /** Informative: product added as discount. */
  _discountItemNo: string;

  /** Informative: UOM of product added as discount. */
  _discountItemUom: string;

  /** Informative: number of times the discount is granted. */
  _discountQuantity: number;
}

export interface AttributeDescription {
  searchId: string;
  name: string;
}
